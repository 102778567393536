(function loadPiwik(window, document, script, dataLayer) {
    const piwikId = caEnvironmentConfig.piwik;
    function stgCreateCookie(a, b, c) {
        var d = '';
        if (c) {
            var e = new Date;
            e.setTime(e.getTime() + 24 * c * 60 * 60 * 1e3), d = '; expires=' + e.toUTCString()
        }
        document.cookie = a + '=' + b + d + '; path=/'
    }
    var isStgDebug = (window.location.href.match('stg_debug') || window.document.cookie.match('stg_debug')) && !window.location.href.match('stg_disable_debug');
    stgCreateCookie('stg_debug', isStgDebug ? 1 : '', isStgDebug ? 365 : -1);
    window[dataLayer] = window[dataLayer] || [], window[dataLayer].push({
        start: (new Date).getTime(),
        event: 'stg.start'
    });
    var scripts = document.getElementsByTagName(script)[0],
        tags = document.createElement(script),
        dl = 'dataLayer' != dataLayer ? '?dataLayer=' + dataLayer : '';
    tags.async = !0, tags.src = '//zta.containers.piwik.pro/'+ piwikId +'.js' + dl, isStgDebug && (tags.src = tags.src + '?stg_debug'), scripts.parentNode.insertBefore(tags, scripts);
    var node = document.createElement('noscript');
    node.innerHTML = '<iframe src=\'//zta.containers.piwik.pro/'+ piwikId +'/noscript.html\' height=\'0\' width=\'0\' style=\'display:none;visibility:hidden\'></iframe>';
    document.getElementsByTagName('head')[0].appendChild(node);
})(window, document, 'script', 'dataLayer');
