
$(document).ready(function () {

	$('.btn-menu').click(function () {
		if ($('body').hasClass('menu-opened') || $('body').hasClass('contact-opened') || $('body').hasClass('location-opened') || $('body').hasClass('side-menu-opened')) {
			$('body').removeClass('menu-opened contact-opened location-opened side-menu-opened');
		}
		else {
			$('body').addClass('menu-opened');
		}
		return false;
	});

	$('.mobile-nav .drop-menu').parent('li').addClass('has-drop');

	$('.mobile-nav .has-drop > a').append('<span class="opener"><span>Open Drop</span></span>');

	$('.mobile-nav .has-drop > a .opener').click(function () {
		var parent_ = $(this).closest('li');
		if (parent_.hasClass('active')) {
			parent_.removeClass('active').children('.drop-menu').slideUp(300);
		} else {
			parent_.addClass('active').children('.drop-menu').slideDown(300);
			parent_.siblings('.active').removeClass('active').children('.drop-menu').slideUp(300);
		};
		return false;
	});

	$('.mobile-tools .contact').click(function () {
		if ($('body').hasClass('menu-opened') || $('body').hasClass('location-opened') || $('body').hasClass('side-menu-opened')) {
			$('body').removeClass('menu-opened location-opened side-menu-opened');
			$('body').addClass('contact-opened');
		}
		else if ($('body').hasClass('contact-opened')) {
			$('body').removeClass('contact-opened');
		}
		else {
			$('body').addClass('contact-opened');
		}
		return false;
	});

	$('.mobile-tools .location').click(function () {
		if ($('body').hasClass('menu-opened') || $('body').hasClass('contact-opened') || $('body').hasClass('side-menu-opened')) {
			$('body').removeClass('menu-opened contact-opened side-menu-opened');
			$('body').addClass('location-opened');
		}
		else if ($('body').hasClass('location-opened')) {
			$('body').removeClass('location-opened');
		}
		else {
			$('body').addClass('location-opened');
		}
		return false;
	});

	$('.btn-side-menu').click(function () {
		$('body').addClass('side-menu-opened');
		return false;
	});

	$('[data-toggle="tooltip"]').tooltip({
		html: true,
		container: 'body'
	});


	$('.selectpicker').selectpicker({
		style: '',
	});

	$('.main-nav > ul > li').has('.mega-menu').addClass('has-mega-menu');
	$('.main-nav > ul > .has-mega-menu > a').click(function () {
		var parent_ = $(this).parent();
		if (parent_.hasClass('open')) {
			parent_.removeClass('open');
		}
		else {
			parent_.addClass('open');
			parent_.siblings('li').removeClass('open');
		}
		$('.country-menu').removeClass('open');
		return false;
	});

	$('.mega-menu .btn-close').click(function () {
		$(this).closest('.mega-menu').closest('li').removeClass('open');
		return false;
	});

	$('.mega-menu ul [data-href]').parent().addClass('has-drop');

	$('.mega-menu ul .has-drop > a').append('<span class="opener"><span>Open Drop</span></span>');

	$('.mega-menu ul .has-drop > a .opener').click(function () {
		var this_ = $(this),
			parent_ = this_.closest('li'),
			drop_menu = this_.parent().attr('data-href'),
			parent_col = parent_.closest('[class*="col-"]');
		if (parent_.hasClass('active')) {
			parent_.removeClass('active');
			$('.mega-menu [data-menu="' + drop_menu + '"]').removeClass('open');
		} else {
			parent_.addClass('active').siblings().removeClass('active');
			$('.mega-menu [data-menu="' + drop_menu + '"]').addClass('open').siblings('.drop-menu').removeClass('open');
		};
		parent_col.next().find('.active').removeClass('active');
		parent_col.next().next().find('.drop-menu').removeClass('open');
		return false;
	});

	$(document).on('mouseup touchend ', function (e) {
		var container = $('.mega-menu');
		if (!container.is(e.target) && container.has(e.target).length === 0 && !$('.main-nav > ul > .has-mega-menu > a').is(e.target) && $('.main-nav > ul > .has-mega-menu > a').has(e.target).length === 0) {
			$('.main-nav > ul > .has-mega-menu').removeClass('open');
		}
	});

	$(document).on('mouseup touchend ', function (e) {
		var container = $('.country-menu');
		if (!container.is(e.target) && container.has(e.target).length === 0 && !$('.tools .location').is(e.target) && $('.tools .location').has(e.target).length === 0) {
			container.removeClass('open');
		}
	});

	$('.user-block .tools .location').click(function () {
		$('.country-menu').toggleClass('open');
		$('.main-nav .open').removeClass('open');
		return false;
	});

	$('.country-menu .btn-close').click(function () {
		$(this).closest('.country-menu').removeClass('open');
		return false;
	});


		// $('.user-block .btn-search, .search-form .close').click(function () {
		// 	$('body').toggleClass('search-form-opened');
		// 	return false;
		// });


	var countries = [
		{ value: 'Austria', data: 'AT' },
		{ value: 'Australia', data: 'ZZ' },
		{ value: 'Algeria', data: 'DZ' },
		{ value: 'Angola', data: 'AO' }
	];

	$('.autocomplete').autocomplete({
		lookup: countries,
	});

	$('.monitor-table > tbody > tr:not(.slide-row):even, .search-table > tbody > tr:not(.slide-row):even').addClass('even');

	$('.monitor-table .arrow, .search-table .arrow').each(function () {
		var this_ = $(this),
			parent_ = this_.closest('tr');
		if (parent_.hasClass('open')) {
			parent_.next('.slide-row').find('.slide').slideDown(300);
		}
	});

	$('.monitor-table .arrow, .search-table .arrow').click(function () {
		var this_ = $(this),
			parent_ = this_.closest('tr');
		if (parent_.hasClass('open')) {
			parent_.removeClass('open');
			parent_.next('.slide-row').find('.slide').slideUp(300);
		}
		else {
			parent_.addClass('open');
			parent_.next('.slide-row').find('.slide').slideDown(300);
			parent_.siblings('tr').removeClass('open');
			parent_.siblings('tr').not(parent_.next()).find('.slide').slideUp(300);
		}
		setTimeout(function () {
			sidePanelHeight();
		}, 300);
	});

	$('.monitor-table .arrow').click(function () {
		setTimeout(function () {
			sidePanelHeight();
		}, 300);
	});

	// $('.side-nav li').has('ul').addClass('has-drop').not('.active').children('ul').hide();
	// $('.side-nav .has-drop > a').append('<span class="opener">Open Drop</span>');
	// $('.side-nav .has-drop > a .opener').click(function () {
	// 	var parent_ = $(this).closest('li');
	// 	if (parent_.hasClass('active')) {
	// 		parent_.removeClass('active').children('ul').slideUp(300);
	// 	} else {
	// 		parent_.addClass('active').children('ul').slideDown(300);
	// 		parent_.siblings('.active').removeClass('active').children('ul').slideUp(300);
	// 	};
	// 	return false;
	// });

	// $('.datepicker').datetimepicker({
	// 	format: 'D-MMM-YYYY',
	// 	icons: {
	// 		time: "fa fa-clock-o",
	// 		date: "fa fa-calendar",
	// 		up: "fa fa-arrow-up",
	// 		down: "fa fa-arrow-down",
	// 		previous: "fa fa-chevron-left",
	// 		next: "fa fa-chevron-right"
	// 	}
	// });
    //
	// $('.datepicker').on('dp.show', function () {
	// 	if ($('.bootstrap-datetimepicker-widget').offset().left < 0) {
	// 		$(this).data("DateTimePicker").options({
	// 			widgetPositioning: {
	// 				horizontal: 'left'
	// 			}
	// 		});
	// 	}
	// });

	$('.combo-select').selectpicker({
		style: '',
		selectedTextFormat: 'count > 2'
	});

	$('.combo-select').on('changed.bs.select', function (e, clickedIndex) {
		var this_ = $(this).find('.dropdown-menu li[data-original-index="' + clickedIndex + '"]'),
			data_optgroup = this_.attr('data-optgroup'),
			data_group = $(this).find('.dropdown-menu li:not(.dropdown-header)[data-optgroup="' + data_optgroup + '"]');
		if (!data_group.not('.selected').length) {
			$(this).find('.dropdown-menu li.dropdown-header[data-optgroup="' + data_optgroup + '"]').addClass('selected');
			console.log('yes')
		}
		else {
			$(this).find('.dropdown-menu li.dropdown-header[data-optgroup="' + data_optgroup + '"]').removeClass('selected');
			console.log('no')
		}
	});

	$('.bootstrap-select.btn-group.combo-select .dropdown-header .text').click(function () {
		var this_ = $(this)
		parent_ = this_.closest('li'),
			data_optgroup = parent_.attr('data-optgroup'),
			data_group = parent_.siblings('li:not(.dropdown-header)[data-optgroup="' + data_optgroup + '"]');
		if (parent_.hasClass('selected')) {
			parent_.removeClass('selected');
			data_group.each(function () {
				if ($(this).hasClass('selected')) {
					$(this).find('a').trigger('click');
				}
			});
		}
		else {
			parent_.addClass('selected');
			data_group.each(function () {
				if (!($(this).hasClass('selected'))) {
					$(this).find('a').trigger('click');
				}
			});
		}
	});

	$('.table-scroll-nav .prev').click(function () {
		$('.search-table-mobile-wrap').animate({
			scrollLeft: '-=140px'
		});
		return false;
	});
	$('.table-scroll-nav .next').click(function () {
		$('.search-table-mobile-wrap').animate({
			scrollLeft: '+=140px'
		});
		return false;
	});

	detectTableScroll();

	$('.search-table-mobile-wrap').on('scroll', function () {
		detectTableScroll();
	});

	$('.search-table .slide .inner').mCustomScrollbar();

	$('.search-table .tooltip-link, .search-table-mobile .tooltip-link').tooltip({
		placement: 'bottom',
		trigger: 'hover',
		container: 'body',
		template: '<div class="tooltip type2" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
	});

});//ready

$(window).on('load', function () {
	$('.mega-menu [class*="col-"]:not(.drop-holder), .mega-menu .drop-menu').mCustomScrollbar();
	setTimeout(function () {
		sidePanelHeight();
	}, 100);
});//load

$(window).on('resize', function () {
	detectTableScroll();
	sidePanelHeight();
});//resize

function detectTableScroll() {
	var tableWidth = $('.search-table-mobile').outerWidth(),
		tableWrapperWidth = $('.search-table-mobile-wrap').outerWidth(),
		tablePosition = $('.search-table-mobile-wrap').scrollLeft();
	if (tablePosition > 0 || tablePosition < tableWidth - tableWrapperWidth) {
		$('.table-scroll-nav').addClass('scroll-process').removeClass('scroll-start scroll-end');
	}
	if (tablePosition == 0) {
		$('.table-scroll-nav').addClass('scroll-start').removeClass('scroll-process');
	}
	if (tablePosition == tableWidth - tableWrapperWidth) {
		$('.table-scroll-nav').addClass('scroll-end').removeClass('scroll-process');
	}
}

function sidePanelHeight() {
	$('.side-panel.type2 .inner').css('min-height', $('.content-holder').outerHeight());
}



