(function () {
    loadCss();
    function loadCss(){
        var rtlLanguages = ['ae','ar','arc','bcc','bqi','ckb','dv','fa','glk','he','ku','mzn','nqo','pnb','ps','sd','ug','ur','yi'];

        var lang = getCookie('language');
        if(rtlLanguages.indexOf(lang) == -1){
            document.getElementById('stylesheet-switcher').href = stylesNames.ltr;
            document.getElementsByTagName('body')[0].classList.remove('rtl');
        }else {
            document.getElementById('stylesheet-switcher').href = stylesNames.rtl;
            document.getElementsByTagName('body')[0].classList.add('rtl');
        }
    }

    function getCookie(name) {
        var matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

})();
